import React from "react"
import { Link, graphql, PageProps } from "gatsby"
import Layout from "../components/layouts/Layout2"
import {
  Hero,
  Breadcrumb,
  StandardContentArea,
  Map,
} from "../components/slices"
import { Helmet } from "react-helmet"
import { renderMetaTags } from "../utils"
import { SliceRenderer } from "../components/slices"

interface StandardPageProps {
  datoCmsService: {
    name: string
    firstName: string
    photo: {
      url: string
    }
    background_image: {
      url: string
    }
  }
  pageContext: {
    parent: {
      title: string
      url: string
    }
  }
}

interface breadcrumbItem {
  title: string
  url: string
}

const Service = ({ data, pageContext, path }: PageProps<StandardPageProps>) => {
  const { name, firstName, photo, background_image, content, seoMetaTags } =
    data.datoCmsTeamMember
  const { title: parentTitle, url: parentUrl } = pageContext.parent
  let breadcrumbData: breadcrumbItem[] = [{ title: "Home", url: "/" }]

  breadcrumbData = [
    ...breadcrumbData,
    {
      title: parentTitle,
      url: parentUrl,
    },
    {
      title: name,
      url: path,
    },
  ]

  const heroProps = {
    title: name,
    variation: "small",
    mainImage: background_image ?? photo,
  }
  return (
    <Layout>
      {seoMetaTags.tags && <Helmet>{renderMetaTags(seoMetaTags.tags)}</Helmet>}
      <Hero {...heroProps} />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
        <SliceRenderer slices={content} variation="small" />
      </div>

      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action__inner">
            <p className="[ title h3 ]">
              Book your appointment with {firstName} now
            </p>
            <Link className="button" to="/contact">
              Contact us
            </Link>
          </div>
        </div>
      </div>

      <Map />
    </Layout>
  )
}

export const query = graphql`
  query TeamMember($url: String) {
    datoCmsTeamMember(url: { eq: $url }) {
      name
      firstName
      photo {
        url
      }
      content {
        __typename
        ... on DatoCmsText {
          id
          title
          text {
            value
          }
          backgroundText
        }
        ... on DatoCmsTextBoxWithImage {
          id
          title
          content
          image {
            url
          }
          link {
            ... on DatoCmsStandardContentPage {
              url
            }
          }
          linkText
          alignment
          originalId
        }
        ... on DatoCmsGalleryBlock {
          title
          content
          gallery {
            url
            ... on DatoCmsFileField {
              url
            }
          }
          link {
            model {
              name
            }
            ... on DatoCmsGallery {
              id
              url
            }
          }
          use4MostRecentGalleryEntries
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`

export default Service
